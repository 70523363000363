import React from 'react'
import Header from './Pages/Header'
import Footer from './Pages/Footer'
import { ImgProvider } from './Assest/ImgProvider'
import FaqSection from './Pages/MainPageSection/FaqSection'
import TestimonialsSection from './Pages/MainPageSection/TestimonialsSection'
import AboutSection from './Pages/MainPageSection/AboutSection'
import AppSection from './Pages/MainPageSection/AppSection'
import ServiceSection from './Pages/MainPageSection/ServiceSection'
import BannerSection from './Pages/MainPageSection/BannerSection'

export default function MainPage() {
    return (
      <>
        <Header />
        <BannerSection />
        <ServiceSection  id="services"/>
        <AppSection  id="app"/>
        <AboutSection id="about" />
        <TestimonialsSection id="testimonials" />
        {/* <FaqSection id="faq" /> */}
        <Footer />
      </>
    )
}
