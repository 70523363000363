import React from 'react'
import { ImgProvider } from '../../Assest/ImgProvider'

export default function AppSection() {
  return (
    <div  id="app" >
    <div className=" max-w-7xl px-2 py-3 mx-auto  tracking-wide mt-10 mb-10 md:px-4 md:mt-10 md:mb-10">
      <div className="grid gap-3 py-8 text-lg leading-6 text-gray-800 md:gap-8 md:grid-cols-2">
        <div className="space-y-3">
          <div className="relative transition-all duration-700  rounded-xl ">
            <div className="w-full p-4 text-left cursor-pointer">
            <div className="flex items-center  ">
             <img src={ImgProvider?.App1}alt='about' />
             {/* <img src={ImgProvider?.App2}alt='about' style={{width:"50%",}} />  */}
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-3 px-2 flexweb1">
        <div className="flex   text-3xl subhedting1">Our App is Available For Android and IOS</div>
          <div className="relative transition-all duration-700  rounded-xl">
            <div  className=" text-left cursor-pointer">
              <div className="flex  items-center justify-between">
              Our app is available for both Android and iOS, giving you access to on-demand yoga, meditation, and wellness resources. Whether you're at home or on the go, enjoy personalized guidance, track your progress, and achieve your wellness goals effortlessly.  </div>
            </div>
            <div className="flex p-2 gap-10 items-center ">
             <img src={ImgProvider?.mobileapp}alt='about' style={{width:"30%"}} />
             <img src={ImgProvider?.iosapp}alt='about' style={{width:"30%"}} />
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
