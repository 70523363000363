import React,{useState} from 'react'
import { ImgProvider } from '../../Assest/ImgProvider'
import { Link } from 'react-router-dom'
import { Plan_URL } from '../../apiConfge'

export default function BannerSection() {
  const [animate, setAnimate] = useState(false);
  // setAnimate(false); // Remove animation class
  setTimeout(() => {
    setAnimate(true); // Re-add animation class after a short delay
  }, 20);
  return (
    <div className='bannersize '>
      <div className='max-w-7xl py-8 px-2   mx-auto'>
        <div className=" flex grid   py-8 mt-20 mb-5  md:grid-cols-2">
          <div className="space-y-3">
            <div className="flex  text-3xl subhedting1 ">Discover Inner Peace and Physical Strength</div>
            <div className="flex items-center mt-2 ">
              Discover the power of yoga with personalized sessions and progress tracking, no matter your experience level.
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex  gap-5 playdwonload" >
              <div className="smallbox flexweb12  items-center">24.5k <span className="smallboxspantext">User per month</span></div>
              <div className=" items-center ">
                <div className='smallbox  mb-2'> 
                  <div className={`animate seven ${animate ? 'start' : ''}`}><span>1</span><span>0</span><span>0</span><span>0</span><span>+</span> &nbsp;
                   <span className="smallboxspantext">Yoga Videos</span>
                  </div>
               </div>
                <div className="smallbox ">8000+ &nbsp;<span className="smallboxspantext">Meditation Audios</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex1  lg:grid-cols-3 sm:grid-cols-1">
          <div className="space-y-3">
            <div className='mt-3 banstyl'>
              <img src={ImgProvider?.banneryoga1} alt='banneryoga' style={{ height: "50%", width: "40%" }} className=' mb-2' />
              <div className=' mt-2 '>
              <Link smooth={true} to={Plan_URL}>
                <div className="bannerbutton " > Start Your Flow </div>
              </Link>
              </div>
            </div>
          </div>
          <div className="space-y-3">
            <img src={ImgProvider?.banneryoga} alt='banneryoga' style={{ width: "100%", height: "80%" }} />
            <div className=' mt-2 bbbb '>
              <Link smooth={true} to={Plan_URL}>
                <div className="bannerbutton " style={{width:"250px",margin:"auto"}}> Start Your Flow </div>
              </Link>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}
